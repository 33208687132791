const state = () => ({
  company: {
    color: [
      {
        hex: '#ff8080',
        active: true
      },
      {
        hex: '#ffaaaa',
        active: false
      },
      {
        hex: '#2cb89a',
        active: false
      },
      {
        hex: '#73d9a2',
        active: false
      }
    ]
  },
  color: '#ff8080'
})

const mutations = {
  setCompany (state, company) {
    state.company = company
  },
  setColor (state, color) {
    state.color = color
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}