export const cookieparser = process.server ? require("cookieparser") : undefined

export const strict = false

export const state = () => ({
  counter: 0
})

export const mutations = {
  increment(state) {
    state.counter++
  }
}

export const actions = {
  nuxtServerInit({ commit }, { req }) {
    
    let token = null, 
        user = {}, 
        intro = false,
        kasir = {}, 
        pelanggan = {}, 
        address = {}, 
        payment = {}

    if (req.headers.cookie) {
      const parsed = cookieparser.parse(req.headers.cookie)
      try {
        token = parsed.token
        user = JSON.parse(parsed.user)

        intro = JSON.parse(parsed.intro)

        kasir = JSON.parse(parsed.kasir)
        pelanggan = JSON.parse(parsed.pelanggan)
        address = JSON.parse(parsed.address)
        payment = JSON.parse(parsed.payment)
      } catch (err) {
        // No valid cookie found
      }
    }

    commit('member/auth/setToken', token)
    commit('member/auth/setUser', user)

    commit('intro/setIntro', intro)

    commit('kasir/setKasir', kasir)
    commit('kasir/setPelanggan', pelanggan)
    commit('kasir/setAddress', address)
    commit('kasir/setPayment', payment)
  }
}
