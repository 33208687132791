const state = () => ({
  kasir: {
    id: '',
    type: 'on_site',
    customer_id: '',
    customer_address_id: '',
    note: '',
    items: [],
    discount_percent: 0,
    discount_value: 0,
    tax_percent: 0,
    tax_value: 0,

    transport_id: '',
    transport_name: '',
    transport_cost: 0,
    transport_qty: 1,
    transport_total_cost: 0,

    dp_paid: 0,

    amount_paid_origin: 0,
    amount_paid_sum: 0,
    amount_paid: 0,
    status: 'complete',
    // payment_method: '',
    // payment_method_bank_name: '',
    // payment_method_ref: '',

    payment: [],

    switch_saldo: false,

    total_days: 0,

    // KASIR
    cashier_id: 0,
    cashier_name: '',

    // DOWN PAYMENT
    switch_dp: false,

    dp: 0,
    dp_payment_method: '',
    dp_payment_method_bank_name: '',
    dp_payment_method_ref: ''
  },
  pelanggan: {},
  address: {},
  payment: {}
})

const mutations = {
  setKasir (state, kasir) {
    state.kasir = kasir
  },
  setPelanggan (state, pelanggan) {
    state.pelanggan = pelanggan
  },
  setAddress (state, address) {
    state.address = address
  },
  setPayment (state, payment) {
    state.payment = payment
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
