import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _07c29bde = () => interopDefault(import('../pages/absen/index.vue' /* webpackChunkName: "pages/absen/index" */))
const _15b475ac = () => interopDefault(import('../pages/bahan/index.vue' /* webpackChunkName: "pages/bahan/index" */))
const _1339fa48 = () => interopDefault(import('../pages/bank/index.vue' /* webpackChunkName: "pages/bank/index" */))
const _a460f96c = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _313628d2 = () => interopDefault(import('../pages/draft/index.vue' /* webpackChunkName: "pages/draft/index" */))
const _709b7c82 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _8e6c00c2 = () => interopDefault(import('../pages/index_catalog.vue' /* webpackChunkName: "pages/index_catalog" */))
const _3b1f85d5 = () => interopDefault(import('../pages/jadwal/index.vue' /* webpackChunkName: "pages/jadwal/index" */))
const _0f2c6f7c = () => interopDefault(import('../pages/kasir/index.vue' /* webpackChunkName: "pages/kasir/index" */))
const _16517157 = () => interopDefault(import('../pages/katalog/index.vue' /* webpackChunkName: "pages/katalog/index" */))
const _0475edb6 = () => interopDefault(import('../pages/konfigurasi/index.vue' /* webpackChunkName: "pages/konfigurasi/index" */))
const _f15effaa = () => interopDefault(import('../pages/kunjungan/index.vue' /* webpackChunkName: "pages/kunjungan/index" */))
const _d16aa7be = () => interopDefault(import('../pages/laporan/index.vue' /* webpackChunkName: "pages/laporan/index" */))
const _a8577098 = () => interopDefault(import('../pages/layanan/index.vue' /* webpackChunkName: "pages/layanan/index" */))
const _098cada2 = () => interopDefault(import('../pages/multi/index.vue' /* webpackChunkName: "pages/multi/index" */))
const _e02a5c62 = () => interopDefault(import('../pages/note.vue' /* webpackChunkName: "pages/note" */))
const _3d3cb951 = () => interopDefault(import('../pages/panduan/index.vue' /* webpackChunkName: "pages/panduan/index" */))
const _03a78efc = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _c50d0934 = () => interopDefault(import('../pages/pegawai/index.vue' /* webpackChunkName: "pages/pegawai/index" */))
const _11015307 = () => interopDefault(import('../pages/pelanggan/index.vue' /* webpackChunkName: "pages/pelanggan/index" */))
const _4c9f0b00 = () => interopDefault(import('../pages/pembayaran/index.vue' /* webpackChunkName: "pages/pembayaran/index" */))
const _1c5f94e1 = () => interopDefault(import('../pages/pengajuan/index.vue' /* webpackChunkName: "pages/pengajuan/index" */))
const _70faedf1 = () => interopDefault(import('../pages/penjadwalan/index.vue' /* webpackChunkName: "pages/penjadwalan/index" */))
const _c3656cfe = () => interopDefault(import('../pages/pinjam/index.vue' /* webpackChunkName: "pages/pinjam/index" */))
const _fc31a366 = () => interopDefault(import('../pages/produk/index.vue' /* webpackChunkName: "pages/produk/index" */))
const _d103a4c8 = () => interopDefault(import('../pages/profil/index.vue' /* webpackChunkName: "pages/profil/index" */))
const _b4b5e37e = () => interopDefault(import('../pages/riwayat/index.vue' /* webpackChunkName: "pages/riwayat/index" */))
const _771f59a8 = () => interopDefault(import('../pages/rutin/index.vue' /* webpackChunkName: "pages/rutin/index" */))
const _734c40df = () => interopDefault(import('../pages/saldo/index.vue' /* webpackChunkName: "pages/saldo/index" */))
const _66a30d90 = () => interopDefault(import('../pages/shift/index.vue' /* webpackChunkName: "pages/shift/index" */))
const _685d6d69 = () => interopDefault(import('../pages/titipan/index.vue' /* webpackChunkName: "pages/titipan/index" */))
const _1fa365ff = () => interopDefault(import('../pages/toko/index.vue' /* webpackChunkName: "pages/toko/index" */))
const _1257a65e = () => interopDefault(import('../pages/transaksi/index.vue' /* webpackChunkName: "pages/transaksi/index" */))
const _6e21347e = () => interopDefault(import('../pages/auth/check.vue' /* webpackChunkName: "pages/auth/check" */))
const _6556071d = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _2035b342 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _3031da79 = () => interopDefault(import('../pages/auth/success.vue' /* webpackChunkName: "pages/auth/success" */))
const _347c66bc = () => interopDefault(import('../pages/jadwal/desktop/index.vue' /* webpackChunkName: "pages/jadwal/desktop/index" */))
const _5047bea6 = () => interopDefault(import('../pages/jadwal/new/index.vue' /* webpackChunkName: "pages/jadwal/new/index" */))
const _3f8b630e = () => interopDefault(import('../pages/jadwal/tambah/index.vue' /* webpackChunkName: "pages/jadwal/tambah/index" */))
const _67b0c408 = () => interopDefault(import('../pages/kunjungan/new/index.vue' /* webpackChunkName: "pages/kunjungan/new/index" */))
const _488c8e7c = () => interopDefault(import('../pages/penjadwalan/new/index.vue' /* webpackChunkName: "pages/penjadwalan/new/index" */))
const _5710a473 = () => interopDefault(import('../pages/jadwal/new/desktop/index.vue' /* webpackChunkName: "pages/jadwal/new/desktop/index" */))
const _3eb8b888 = () => interopDefault(import('../pages/jadwal/new/tambah/index.vue' /* webpackChunkName: "pages/jadwal/new/tambah/index" */))
const _2365221b = () => interopDefault(import('../pages/jadwal/new/desktop/tambah/index.vue' /* webpackChunkName: "pages/jadwal/new/desktop/tambah/index" */))
const _54731a76 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f6397de4 = () => interopDefault(import('../pages/jadwal/new/desktop/ubah/_id.vue' /* webpackChunkName: "pages/jadwal/new/desktop/ubah/_id" */))
const _0f95d40a = () => interopDefault(import('../pages/jadwal/new/ubah/_id.vue' /* webpackChunkName: "pages/jadwal/new/ubah/_id" */))
const _12d3ea27 = () => interopDefault(import('../pages/transaksi/payment/multi/_id.vue' /* webpackChunkName: "pages/transaksi/payment/multi/_id" */))
const _30a4d098 = () => interopDefault(import('../pages/transaksi/payment/pembayaran/_id.vue' /* webpackChunkName: "pages/transaksi/payment/pembayaran/_id" */))
const _65ab0ec0 = () => interopDefault(import('../pages/auth/reset/_slug.vue' /* webpackChunkName: "pages/auth/reset/_slug" */))
const _5c085e79 = () => interopDefault(import('../pages/invoice/print/_slug.vue' /* webpackChunkName: "pages/invoice/print/_slug" */))
const _36ed6728 = () => interopDefault(import('../pages/jadwal/ubah/_id.vue' /* webpackChunkName: "pages/jadwal/ubah/_id" */))
const _27bd7d3d = () => interopDefault(import('../pages/transaksi/payment/_id.vue' /* webpackChunkName: "pages/transaksi/payment/_id" */))
const _5c6b351d = () => interopDefault(import('../pages/feedback/_slug.vue' /* webpackChunkName: "pages/feedback/_slug" */))
const _b83e0bca = () => interopDefault(import('../pages/invoice/_slug.vue' /* webpackChunkName: "pages/invoice/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/absen",
    component: _07c29bde,
    name: "absen"
  }, {
    path: "/bahan",
    component: _15b475ac,
    name: "bahan"
  }, {
    path: "/bank",
    component: _1339fa48,
    name: "bank"
  }, {
    path: "/dashboard",
    component: _a460f96c,
    name: "dashboard"
  }, {
    path: "/draft",
    component: _313628d2,
    name: "draft"
  }, {
    path: "/home",
    component: _709b7c82,
    name: "home"
  }, {
    path: "/index_catalog",
    component: _8e6c00c2,
    name: "index_catalog"
  }, {
    path: "/jadwal",
    component: _3b1f85d5,
    name: "jadwal"
  }, {
    path: "/kasir",
    component: _0f2c6f7c,
    name: "kasir"
  }, {
    path: "/katalog",
    component: _16517157,
    name: "katalog"
  }, {
    path: "/konfigurasi",
    component: _0475edb6,
    name: "konfigurasi"
  }, {
    path: "/kunjungan",
    component: _f15effaa,
    name: "kunjungan"
  }, {
    path: "/laporan",
    component: _d16aa7be,
    name: "laporan"
  }, {
    path: "/layanan",
    component: _a8577098,
    name: "layanan"
  }, {
    path: "/multi",
    component: _098cada2,
    name: "multi"
  }, {
    path: "/note",
    component: _e02a5c62,
    name: "note"
  }, {
    path: "/panduan",
    component: _3d3cb951,
    name: "panduan"
  }, {
    path: "/payment",
    component: _03a78efc,
    name: "payment"
  }, {
    path: "/pegawai",
    component: _c50d0934,
    name: "pegawai"
  }, {
    path: "/pelanggan",
    component: _11015307,
    name: "pelanggan"
  }, {
    path: "/pembayaran",
    component: _4c9f0b00,
    name: "pembayaran"
  }, {
    path: "/pengajuan",
    component: _1c5f94e1,
    name: "pengajuan"
  }, {
    path: "/penjadwalan",
    component: _70faedf1,
    name: "penjadwalan"
  }, {
    path: "/pinjam",
    component: _c3656cfe,
    name: "pinjam"
  }, {
    path: "/produk",
    component: _fc31a366,
    name: "produk"
  }, {
    path: "/profil",
    component: _d103a4c8,
    name: "profil"
  }, {
    path: "/riwayat",
    component: _b4b5e37e,
    name: "riwayat"
  }, {
    path: "/rutin",
    component: _771f59a8,
    name: "rutin"
  }, {
    path: "/saldo",
    component: _734c40df,
    name: "saldo"
  }, {
    path: "/shift",
    component: _66a30d90,
    name: "shift"
  }, {
    path: "/titipan",
    component: _685d6d69,
    name: "titipan"
  }, {
    path: "/toko",
    component: _1fa365ff,
    name: "toko"
  }, {
    path: "/transaksi",
    component: _1257a65e,
    name: "transaksi"
  }, {
    path: "/auth/check",
    component: _6e21347e,
    name: "auth-check"
  }, {
    path: "/auth/forgot",
    component: _6556071d,
    name: "auth-forgot"
  }, {
    path: "/auth/login",
    component: _2035b342,
    name: "auth-login"
  }, {
    path: "/auth/success",
    component: _3031da79,
    name: "auth-success"
  }, {
    path: "/jadwal/desktop",
    component: _347c66bc,
    name: "jadwal-desktop"
  }, {
    path: "/jadwal/new",
    component: _5047bea6,
    name: "jadwal-new"
  }, {
    path: "/jadwal/tambah",
    component: _3f8b630e,
    name: "jadwal-tambah"
  }, {
    path: "/kunjungan/new",
    component: _67b0c408,
    name: "kunjungan-new"
  }, {
    path: "/penjadwalan/new",
    component: _488c8e7c,
    name: "penjadwalan-new"
  }, {
    path: "/jadwal/new/desktop",
    component: _5710a473,
    name: "jadwal-new-desktop"
  }, {
    path: "/jadwal/new/tambah",
    component: _3eb8b888,
    name: "jadwal-new-tambah"
  }, {
    path: "/jadwal/new/desktop/tambah",
    component: _2365221b,
    name: "jadwal-new-desktop-tambah"
  }, {
    path: "/",
    component: _54731a76,
    name: "index"
  }, {
    path: "/jadwal/new/desktop/ubah/:id?",
    component: _f6397de4,
    name: "jadwal-new-desktop-ubah-id"
  }, {
    path: "/jadwal/new/ubah/:id?",
    component: _0f95d40a,
    name: "jadwal-new-ubah-id"
  }, {
    path: "/transaksi/payment/multi/:id?",
    component: _12d3ea27,
    name: "transaksi-payment-multi-id"
  }, {
    path: "/transaksi/payment/pembayaran/:id?",
    component: _30a4d098,
    name: "transaksi-payment-pembayaran-id"
  }, {
    path: "/auth/reset/:slug?",
    component: _65ab0ec0,
    name: "auth-reset-slug"
  }, {
    path: "/invoice/print/:slug?",
    component: _5c085e79,
    name: "invoice-print-slug"
  }, {
    path: "/jadwal/ubah/:id?",
    component: _36ed6728,
    name: "jadwal-ubah-id"
  }, {
    path: "/transaksi/payment/:id?",
    component: _27bd7d3d,
    name: "transaksi-payment-id"
  }, {
    path: "/feedback/:slug?",
    component: _5c6b351d,
    name: "feedback-slug"
  }, {
    path: "/invoice/:slug?",
    component: _b83e0bca,
    name: "invoice-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
