import firebase from 'firebase'

const config = {
  apiKey: "AIzaSyCxIrrDhsescsSP4PQXqDxBJXUPXbmIJcg",
  authDomain: "fdv-app-printer.firebaseapp.com",
  projectId: "fdv-app-printer",
  storageBucket: "fdv-app-printer.appspot.com",
  messagingSenderId: "111505496087",
  appId: "1:111505496087:web:dff213fddf6ce2ae7f2452",
  measurementId: "G-8PC226L83D"
}

if (!firebase.apps.length) { 
  firebase.initializeApp(config)
}

export const db = firebase.firestore()

export default (ctx, inject) => {
  inject('firebase', firebase)
}